<template>
    <div class="referral-edit">
        <edit :fs="referralFields" api="referrals" :id="id" title="Referral" @saved="saved"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { referralFields } from "@/config";
export default {
    props: ["id"],
    components: {
        Edit
    },
    computed: {
        referralFields() {
            return referralFields;
        }
    },
    methods: {
        saved() {
            this.$router.push("/admin/referrals");
        }
    }
};
</script>