<template>
    <div class="referral-index">
        <md-tabs ref="tabs" @change="tabChange">
            <md-tab md-label="Referrals" :md-active="tabIndex==0">
                <referral-list ref="list" v-if="tabIndex==0"></referral-list>
            </md-tab>
            <md-tab md-label="Add Referral" :md-active="tabIndex==1">
                <referral-edit ref="add" v-if="tabIndex==1"></referral-edit>
            </md-tab>
            <md-tab md-label="Edit Referral" v-if="tabIndex==2" :md-active="tabIndex==2">
                <referral-edit ref="edit" :id="id" v-if="tabIndex==2"></referral-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import ReferralList from "@/components/Referral/ReferralList";
import ReferralEdit from "@/components/Referral/ReferralEdit";
export default {
    props: ["id"],
    components: {
        ReferralList,
        ReferralEdit
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/referrals");
            }
            if (index == 1) {
                this.$router.push("/admin/referrals/new");
            }
            if (index == 2) {
                this.$router.push(`/admin/referrals/${this.id}`);
            }
        }
    }
};
</script>