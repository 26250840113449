<template>
    <div class="referral-list">
        <list :fs="referralFields" api="referrals" editLink="/admin/referrals" addLink="/admin/referrals/new" title="Referrals"></list>
    </div>
</template>
<script>
import List from "@/components/List";
import { referralFields } from "@/config";
import { mapGetters } from "vuex";
export default {
    components: {
        List
    },
    computed: {
        referralFields() {
            return referralFields;
        }
    }
};
</script>